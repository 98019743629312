<template>
  <!-- <el-page-header @back="goBack" content="详情页面"> </el-page-header> -->
  <div class="content" :style="'min-height:' + minHeight + 'px;'">
    <section class="container">
      <div class="index-header">
        <a class="logo"><img class="logo-img" src="../../assets/logo.jpg" /></a>
        <span v-if="!$isWeixin()" @click="logout" class="fr mgt20 logout">退出登录</span>
      </div>
    </section>
    <section class="container">
      <el-carousel :height="contentHeight + 'px'">
        <el-carousel-item v-for="item in banners" :key="item">
          <img :height="imgHeight + 'px'" width="100%" :src="item" />
        </el-carousel-item>
      </el-carousel>
    </section>
    <section class="container course-header">我的课程</section>

    <section class="container course-list">
      <el-card class="mgb10" :body-style="{ marginLeft: '-10px', marginRight: '-10px' }" is-always-shadow
        v-for="item in courses" :key="item.id">
        <el-row>
          <el-col :xs="24" :sm="8" :md="7" :lg="7" :xl="7" style="padding: 0 10px">
            <a :href="'/coursedetail?id=' + item.id">
              <img width="100%" :src="item.coverimage" />
            </a>
          </el-col>
          <el-col :xs="24" :sm="16" :md="17" :lg="17" :xl="17" style="padding: 0 10px">
            <div class="course-right">
              <a :href="'/coursedetail?id=' + item.id">
                <p class="course-title">{{ item.name }}</p>
              </a>
              <el-tag class="mgr10" size="mini" type="success" v-for="tag in tags(item.tags)" :key="tag">{{ tag }}
              </el-tag>
              <div class="course-intro">
                <p v-html="item.content">

                </p>
              </div>
              <a :href="'/coursedetail?id=' + item.id">
                <el-button class="btn" style="height: 32px; line-height: 18px; padding: 7px 15px" type="primary">去学习
                </el-button>
              </a>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <!-- <m-loadmore :status="more" :onRichBottom="loadMore"></m-loadmore> -->

      <infinite-loading spinner="bubbles" @infinite="infiniteHandler" :distance="200" class="more-nav container">
        <div slot="spinner">努力加载中</div>
        <div slot="no-more">没有更多数据</div>
        <div slot="no-results">没有更多数据1</div>
      </infinite-loading>

    </section>


  </div>
</template>

<script>
import { banners, courseList } from "../../api";
//import loadMore from "../loadmore/Index";
import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    InfiniteLoading
  },
  data() {
    return {
      banners: [],
      courses: [],
      page: 1,
      contentWidth: 1080,
      contentHeight: 0,
      listHeight: 0,
      imgHeight: 0,
      minHeight: 0,
      more: 'more',
      disabled: false,
      sn: this.$route.query.sn,
    };
  },

  created() { },
  mounted() {
    //console.log(this.wx);
    this.initBanner();
    //this.courseList();
    this.minHeight = window.innerHeight;
    this.contentWidth = window.innerWidth > 1080 ? 1080 : window.innerWidth; //这样一刷新页面 依旧可以先获取获取此时的屏幕宽
    this.contentHeight = (this.contentWidth / 16) * 6;
    this.imgHeight = (this.contentWidth / 16) * 6;
    this.listHeight = (this.minHeight - 60 - this.contentHeight);
  },
  methods: {
    initBanner() {
      banners().then((res) => {
        //console.log("banner", res);
        this.banners = res.data;
      });
    },
    infiniteHandler($state) {
      const self = this;
      const _$state=$state;
      //this.more = 'loading';
      courseList({ page: this.page, sn: this.sn, platform: this.$isWeixin() }).then((res) => {
        //console.log("course", res);
        if (res.code != 0 && res.data.data.length > 0) {
          //console.log('ddddddddddddd');
          
          self.courses = self.courses.concat(res.data.data);
            //self.more = 'more';
            //self.disabled = false;
            self.page++;
            _$state.loaded();            
          
        } else {
          // self.more = 'nomore';
          // self.disabled = true;
          _$state.complete();
        }
      });
    },
    // loadMore() {
    //   //console.log('load-more');
    //   if (!this.disabled) {
    //     //this.courseList();
    //   }
    // },
    tags(val) {
      return val.split(',');
    },
    logout() {
      console.log('d');
      sessionStorage.removeItem("userinfo");
      sessionStorage.removeItem("token");
      localStorage.removeItem("token1");
      this.$router.push('/');
    }
  },
};
</script>
<style scoped>
.course-list {
  background-color: #fafafa;
  width: 100%;
  padding: 1px 20px 50px;
  min-height: 410px;
}
.more-nav{
    margin-top: 20px;
    color: #cfcfcf;
  }
</style>